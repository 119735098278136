import { api } from '../api';
import { setUser } from './authSlice';

export const authApi = api.injectEndpoints({
    endpoints: (builder) => ({
        loginUser: builder.mutation({
            query(body) {
                return {
                    url: '/user/login',
                    method: 'POST',
                    body
                }
            },
            transformResponse: (response, meta, arg) => response.data,
            transformErrorResponse: (response, meta, arg) => {
                const error = new Error(response.data.message);
                error.status = response.status;
                return error;
            },
            async onQueryStarted(args, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                    //console.log(data);
                    if (data) {
                        dispatch(setUser(data));
                        localStorage.removeItem('kfcUser');
                        localStorage.setItem("kfcUser", JSON.stringify(data));
                    }

                } catch (error) {
                    //console.log('onQuery', error);
                }
            },
        }),
        signUpUser: builder.mutation({
            query(body) {
                return {
                    url: '/user/signup',
                    method: 'POST',
                    body
                }
            },
            transformErrorResponse: (response, meta, arg) => {
                const { status, message } = response?.data
                const error = new Error(message);
                error.status = status;
                return error;
            },
        }),
        forgotPassword: builder.mutation({
            query: payload => ({
                url: '/user/forgot-password',
                method: 'POST',
                body: payload
            }),
        }),
        forgotPasswordChange: builder.mutation({
            query: ({ token, ...payload }) => ({
              url: '/user/forgot-password-change',
              method: 'POST',
              body: payload,
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }),
        }),
    })
})

export const { useLoginUserMutation, useSignUpUserMutation, useForgotPasswordChangeMutation, useForgotPasswordMutation } = authApi;