import React, { useState } from "react";
import Slider from "react-slick";
import Logo from "../assets/images/logounit.png";
import Steel from "../assets/images/EpicVillian1_MOSCard.png";
import Dipest from "../assets/images/EpicVillian2_DipsterCard.png";
import BUNCOLLECTOR from "../assets/images/EpicVillian3_BunCollector.png";
import CRIMEMASTER from "../assets/images/Villian4_Card.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import PromptsSelection from "../components/PromptsSelection";
import { Link } from "react-router-dom";

function SelectCharacterPage() {
  const [selectedCharacter, setSelectedCharacter] = useState(null);
  const [centeredIndex, setCenteredIndex] = useState(null);

  const characters = [
    { name: "MAN OF STEAL", imgSrc: Steel },
    { name: "CRIMEMASTER CRUNCH", imgSrc: CRIMEMASTER },
    { name: "DIPSTER", imgSrc: Dipest },
    { name: "BUNCOLLECTOR", imgSrc: BUNCOLLECTOR },
  ];

  const handleSelect = () => {
    if (centeredIndex !== null) {
      setSelectedCharacter(characters[centeredIndex]);
    }
  };

  // Slick slider settings
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 4, // Default for larger screens
    centerMode: true,
    centerPadding: "0", // No padding for larger screens
    arrows: false,
    beforeChange: (_, next) => setCenteredIndex(next),
    focusOnSelect: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1, // Show only 1 main slide on mobile
          centerMode: true,
          centerPadding: "60px", // Show parts of the neighboring slides
        },
      },
    ],
  };

  return (
    <div className="character-page">
      <Link to="/">
        <img className="logo-unit" src={Logo} alt="logo" />
      </Link>
      <div className="character-page-wrapper">
        {!selectedCharacter ? (
          <>
            <h2 className="heading">CHOOSE THE VILLAIN</h2>
            <h1 className="heading-t">YOU WANT TO BEAT!</h1>
            <div className="character-slider">
              <Slider {...settings}>
                {characters.map((character, index) => (
                  <div
                    key={index}
                    className={`character-card-1 ${
                      centeredIndex === index ? "centered" : ""
                    }`}
                    onClick={() => setCenteredIndex(index)}
                  >
                    <img
                      className="img-main"
                      src={character.imgSrc}
                      alt={character.name}
                    />
                    {/* <h3 className="name">{character.name}</h3> */}
                  </div>
                ))}
              </Slider>
            </div>
            <button
              className={`select-button ${
                centeredIndex === null ? "disabled" : ""
              }`}
              onClick={handleSelect}
              disabled={centeredIndex === null}
            >
              Next
            </button>
          </>
        ) : (
          <PromptsSelection character={selectedCharacter} />
        )}
      </div>
    </div>
  );
}

export default SelectCharacterPage;
