import { createSlice } from '@reduxjs/toolkit';

const storedMediaData = localStorage.getItem('AvatarData');
const initialState = {
  mediaData: storedMediaData ? JSON.parse(storedMediaData) : null,
};

export const avatarSlice = createSlice({
  name: 'avatarSlice',
  initialState,
  reducers: {
    setAvatarData: (state, { payload }) => {
      // Cleared previous avatar data from localStorage
      localStorage.removeItem('AvatarData');
      
      // Set new avatar data
      state.mediaData = payload;
      
      // Save the new avatar data to localStorage
      localStorage.setItem('AvatarData', JSON.stringify(payload));
    },
    clearAvatarData: (state) => {
      state.mediaData = null;
      // Remove from localStorage
      localStorage.removeItem('AvatarData');
    },
  },
});

export const { setAvatarData, clearAvatarData } = avatarSlice.actions;

export default avatarSlice.reducer;
