import React, { useEffect } from "react";
import { Formik, Form, ErrorMessage, Field } from "formik";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { object, string } from "yup";
import { useLoginUserMutation } from "../store/auth/authApi";
import { toast, ToastContainer } from "react-toastify";
import { useSelector } from "react-redux";
import Welcome from "../assets/images/welcome.png";
import Logo from "../assets/images/logounit.png";

function SignIn() {
  const navigate = useNavigate();
  const location = useLocation();
  const userInfo = useSelector((state) => state.auth?.userInfo);
  const from = location?.pathname || "/";

  const [loginUser, { data, error, isLoading }] = useLoginUserMutation();

  useEffect(() => {
    if (userInfo) navigate(from);
  }, []);

  useEffect(() => {
    if (error) {
      const { message } = error;
      toast.error(message, {
        position: "top-right",
      });
    }
    if (data?._id) {
      navigate("/", { replace: true });
    }
  }, [error, data, navigate]);

  const initialValues = {
    email: "",
    password: "",
  };

  const validationSchema = object({
    email: string()
      .email("Invalid email address")
      .required("Email is required"),
    password: string().required("Password is required"),
  });

  const handleSubmit = async (values) => {
    await loginUser(values);
  };

  return (
    <div className="login">
      <Link to="/">
        <img className="logo" src={Logo} alt="logo" />
      </Link>
      <div className="login-wrapper container">
        <ToastContainer />
        <header>
          <img className="welcome" src={Welcome} alt="welcome" />
        </header>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          <Form className="form-lg">
            <div className="form-wrapper">
              <div>
                <Field
                  type="email"
                  id="email"
                  name="email"
                  className="form-control "
                  placeholder="Email"
                />
                <ErrorMessage
                  name="email"
                  component="div"
                  className="error error-email"
                />
              </div>
              <div>
                <Field
                  type="password"
                  id="password"
                  name="password"
                  className="form-control"
                  placeholder="Password"
                />
                <ErrorMessage
                  name="password"
                  component="div"
                  className="error error-pass"
                />
              </div>
            </div>
            <div className="login-btn-wrapper">
              <a
                className="forgot-password"
                onClick={() => navigate("/forgot-password-change")}
              >
                Forgot Password?
              </a>
              {isLoading ? (
                <div className="spinner" style={{ marginBottom: "10px" }}></div>
              ) : null}
              <button
                type="submit"
                className="btn btn-primary login-btn"
                disabled={isLoading}
              >
                {isLoading ? "Logging in..." : "Login"}
              </button>
              <span className="btn-signup">New member? &nbsp;
                <a onClick={() => navigate("/sign-up")}>
                  Sign up
                </a>
              </span>
            </div>
          </Form>
        </Formik>
      </div>{" "}
    </div>
  );
}

export default SignIn;
