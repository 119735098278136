import { useSelector } from "react-redux";

function ShareModal({ isOpen, onClose }) {
  const mediaData = useSelector((state) => state.avatar.mediaData);

  if (!isOpen) return null;

  const imageUrl = mediaData?.avtar;

  const imageDescription = mediaData?.villain
    ? `Ready to battle the villain: ${mediaData.villain}`
    : "Check out my epic transformation!";

  const whatsAppShareUrl = `https://wa.me/?text=${encodeURIComponent(imageDescription)}%20${encodeURIComponent(imageUrl)}`;

  const instagramMessage = `Check out my transformation! ${imageDescription} ${imageUrl}`;
  const instagramShareUrl = `https://www.instagram.com/direct/new/?text=${encodeURIComponent(instagramMessage)}`;

  // Inline Styles
  const modalOverlayStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
  };

  const modalContentStyle = {
    backgroundColor: 'white',
    padding: '20px',
    borderRadius: '8px',
    width: '80%',
    maxWidth: '500px',
    textAlign: 'center',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    position: 'relative',
  };

  const modalButtonsStyle = {
    margin: '20px 0',
  };

  const buttonStyle = {
    backgroundColor: '#007bff',
    color: 'white',
    border: 'none',
    padding: '10px 20px',
    borderRadius: '5px',
    cursor: 'pointer',
    textDecoration: 'none',
    display: 'inline-block',
    margin: '5px',
  };

  const closeButtonStyle = {
    backgroundColor: 'transparent',
    border: 'none',
    color: '#dc3545',
    fontSize: '24px',
    position: 'absolute',
    top: '10px',
    right: '10px',
    cursor: 'pointer',
  };

  return (
    <div className="modal-overlay share-modal" style={modalOverlayStyle} onClick={onClose}>
      <div className="modal-content" style={modalContentStyle} onClick={(e) => e.stopPropagation()}>
        <button style={closeButtonStyle} onClick={onClose}>
          &times;
        </button>

        <h3>Share your epic transformation!</h3>
        <div className="modal-buttons" style={modalButtonsStyle}>
          {/* Twitter Share Button */}
          <a href={whatsAppShareUrl} target="_blank" rel="noopener noreferrer">
            <button >
              Share on WhatsApp
            </button>
          </a>

          {/* Instagram Share Button */}
          <a href={instagramShareUrl} target="_blank" rel="noopener noreferrer">
            <button >
              Share on Instagram
            </button>
          </a>
        </div>
      </div>
    </div>
  );
}

export default ShareModal;
