import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const api = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl:process.env.REACT_APP_API_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.userInfo?.token;
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
        return headers;
      }
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
  }),
  refetchOnFocus: true,
  tagTypes: [
    //"Users",
  ],
  endpoints: () => ({}),
});
