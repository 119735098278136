import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../store/auth/authSlice";

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
// import "./SliderStyles.css";
// import "../styles/LandingPage.css";
import logo from "../assets/images/logounit.png";
import ImgGrad from "../assets/images/red-gradiant.png";
import PopCorn from "../assets/images/popcorn-1.png";
import { useGetUserAvatarQuery } from "../store/avatar/avatarApi";
import Popcorn from "../assets/images/popcorn-bucket.png";
import Chicken from "../assets/images/chicken.png";
import Burgur from "../assets/images/burgur-right.png";
import Role from "../assets/images/bottom-role.png";
import startBox from "../assets/images/startBox.png";
import output from "../assets/images/output.png";
import VideoMP from "../assets/images/BG v1.mp4";

function LandingPage() {
  const userInfo = useSelector((state) => state.auth?.userInfo);
  const isLoggedIn = !!userInfo;
  const token = userInfo?.token;
  const { data: avatarsResponse, isLoading } = useGetUserAvatarQuery(
    token || "",
    {
      skip: !isLoggedIn,
    }
  );

  const avatarImages = avatarsResponse?.data
    ?.map((avatar) => avatar?.avtar)
    .filter((image) => image) || [output];

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    dispatch(logout());
    navigate("/");
  };

  const handleGAClick = () => {

    if (window.gtag) {
      window.gtag('event', 'conversion', {
        'allow_custom_scripts': true,
        'send_to': 'DC-14504723/kfchero/kfchweb+standard',
      });
    }
  }

  return (
    <div className="landing-main-page">
      <img className="left-chicken" alt="chicken" src={Chicken} />
      <img className="right-burgur" alt="chicken" src={Burgur} />
      <img className="bottom-role" alt="chicken" src={Role} />
      <video autoPlay muted loop playsInline className="video-background">
        <source src={VideoMP} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="landing-page">
        <div className="landing-wrap container">
          <header>
            <Link to="/" className="logo">
              <img src={logo} alt="logo" />
            </Link>
            <div className="copy-header">
              <h2>Villains of the crunch are rising</h2>
              <h2>It’s your time to rise </h2>
              <h1>As the heroes of the Epic Crunch</h1>
            </div>
          </header>
          <div
            className={`link-wrap ${isLoggedIn ? "logged-in" : "logged-out"}`}
          >
            <div className="button-container">
              {isLoggedIn ? (
                <div className="start-wrapper">
                  <div className="swiper-container container">
                    <Swiper
                      spaceBetween={0} // Set smaller space between slides
                      slidesPerView={3} // Show 3 slides at a time
                      centeredSlides={true} // Center the active slide
                      loop={false} // Enable looping
                      pagination={{
                        clickable: true,
                      }}
                      modules={[Pagination]}
                      slideToClickedSlide={true} // Allow clicking to center slide
                      className="custom-swiper"
                      breakpoints={{
                        768: {
                          // On tablets, show 2 slides
                          spaceBetween: 60, // Moderate space between slides on tablets
                        },
                      }}
                    >
                      {avatarImages.map((image, index) => (
                        <SwiperSlide className="slide" key={index}>
                          <img src={image} alt={`Slide ${index + 1}`} />
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  </div>
                  <Link to="/select" onClick={handleGAClick}>
                    <button >Start</button>
                  </Link>
                  <noscript>
                    <img src="https://ad.doubleclick.net/ddm/activity/src=14504723;type=kfchero;cat=kfchweb;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ord=1?" width="1" height="1" alt="" />
                  </noscript>
                </div>
              ) : (
                <Link to="/sign-up">
                  <button>Sign Up</button>
                </Link>
              )}
            </div>
            {!isLoggedIn && (
              <Link to="/sign-in">
                <button>Login</button>
              </Link>
            )}
            {isLoggedIn && (
              <button
                className="log-out"
                onClick={handleLogout}
                style={{ position: "absolute", top: 10, right: 10 }}
              >
                Log out
              </button>
            )}
          </div>
        </div>
      </div>

      <div className="inst-wrapper">
        <img className="popcorn-bucket" src={Popcorn} alt="pocorn-buckect" />
        <div className="inst-wrapper-top container">
          <div className="header">
            <p>SUIT UP AND BECOME THE</p>
            <h2>EPIC HERO OF THE CRUNCH</h2>
          </div>
          <div className="instruction-wrapper ">
            <div className="instra-item">
              <h3 className="number">1.</h3>
              <p className="instr-copy">
                <span className="para">Choose a villain you want to fight</span>
              </p>
            </div>
            <div className="instra-item">
              <h3 className="number">2.</h3>
              <p className="instr-copy">
                <span className="para">
                  {" "}
                  Pick your powers & generate your KFC Superhero avatar
                </span>
              </p>
            </div>
            <div className="instra-item">
              <h3 className="number">3.</h3>
              <p className="instr-copy">
                <span className="para">
                  Upload your selfie according to the instructions
                </span>
              </p>
            </div>
            <div className="instra-item">
              <h3 className="number">4.</h3>
              <p className="instr-copy">
                <span className="para">Generate your avatar & share it!</span>
              </p>
            </div>
          </div>
        </div>

        <div className="red-grad-wrap container">
          {/* <img className="red-tape" src={ImgGrad} alt="img-grad" /> */}
          THE GRAND PRIZE AWAITS AT THE END
          <img className="popcorn" src={PopCorn} alt="img-grad" />
        </div>
      </div>
    </div>
  );
}

export default LandingPage;
