import "./assets/css/style.scss";
import { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import LandingPage from "./pages/LandingPage";
import SignUp from "./pages/SignUp";
import UploadAvatar from "./pages/UploadAvatar";
import SelectCharacterPage from "./pages/SelectCharacterPage";
import OutputPage from "./pages/OutputPage";
import SignIn from "./pages/SignIn";
import ForgotPassword from "./pages/ForgotPassword";
import NotFound from "./pages/NotFound";
import DashBoardForAdmin from "./pages/DashBoardForAdmin";
import { setUser } from "./store/auth/authSlice";
import "react-toastify/dist/ReactToastify.css";

function App() {
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.auth?.userInfo);

  useEffect(() => {
    const localUserInfo = JSON.parse(localStorage.getItem("kfcUser"));
    if (localUserInfo) {
      dispatch(setUser(localUserInfo));
    }
  }, [dispatch]);

  return (
    <Routes>
      {/* Home page or Dashboard based on user type */}
      <Route
        path="/"
        element={userInfo ? (userInfo.isAdmin ? <DashBoardForAdmin /> : <LandingPage />) : <LandingPage />}
      />

      {/* Public Routes */}
      <Route path="/sign-up" element={<SignUp />} />
      <Route path="/sign-in" element={<SignIn />} />
      <Route path="/forgot-password-change" element={<ForgotPassword />} />

      {/* User-specific routes */}
      {userInfo && !userInfo.isAdmin && (
        <>
          <Route path="/upload" element={<UploadAvatar />} />
          <Route path="/output" element={<OutputPage />} />
          <Route path="/select" element={<SelectCharacterPage />} />
        </>
      )}

      {/* route for not found */}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default App;
