import React, { useRef } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  useForgotPasswordMutation,
  useForgotPasswordChangeMutation,
} from "../store/auth/authApi";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import Welcome from "../assets/images/welcome.png";
import Logo from "../assets/images/logounit.png";

function ForgotPassword() {
  const navigate = useNavigate();
  const [queryParameters] = useSearchParams();
  const token = queryParameters.get("token");
  const passwordChangeRef = useRef();
  const emailFormRef = useRef();

  const [forgotPassword] = useForgotPasswordMutation();
  const [forgotPasswordChange] = useForgotPasswordChangeMutation();

  const initialValues = {
    email: "",
  };

  const changePasswordInitialValues = {
    newPassword: "",
    confirmPassword: "",
  };

  const validationSchema = Yup.object({
    email: Yup.string().email("Invalid email address").required("Required"),
  });

  const changePasswordValidationSchema = Yup.object({
    newPassword: Yup.string()
      .required("Required")
      .min(6, "Password must be at least 6 characters long"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
      .required("Required"),
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);
    try {
      const response = await forgotPassword(values).unwrap();
      const { status, message } = response;

      if (status === "success") {
        toast.success(message, {
          position: "top-right",
        });
        // Navigate to sign-in page
        setTimeout(() => {
          navigate("/sign-in");
        }, 2000);
      }
      emailFormRef.current.resetForm();
    } catch (error) {
      // Handle errors here
      toast.error(error?.data?.message || "An error occurred", {
        position: "top-right",
      });
    } finally {
      setSubmitting(false);
    }
  };

  const handleChangePasswordSubmit = async (values, { setSubmitting }) => {
    const { newPassword } = values;
    const payload = {
      password: newPassword,
    };
    setSubmitting(true);
    try {
      // Pass the token in the request
      const response = await forgotPasswordChange({
        token,
        ...payload,
      }).unwrap();
      // Check the response for success
      if (response?.status == "success" && response?.message) {
        toast.success(response.message, { position: "top-right" });
        passwordChangeRef.current.resetForm();
        // Navigate to sign-in page
        setTimeout(() => navigate("/sign-in"), 2000);
      } else {
        toast.error("Unexpected response structure.", {
          position: "top-right",
        });
      }
    } catch (error) {
      toast.error(error?.data?.message || "An error occurred", {
        position: "top-right",
      });
      passwordChangeRef.current.resetForm();
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className="forgot-pass">
      <div className="forgot-pass-wrapper container">
        <ToastContainer />
        <img className="welcome" src={Welcome} alt="welcome" />
        <Link to="/">
          <img className="logo-unit" src={Logo} alt="welcome" />
        </Link>
        <div className="wrapper-white">
          {token?.length > 0 ? (
            <Formik
              initialValues={changePasswordInitialValues}
              validationSchema={changePasswordValidationSchema}
              onSubmit={handleChangePasswordSubmit}
              innerRef={passwordChangeRef}
            >
              <Form>
                <div className="test-wrapp-forgot">
                  <Field
                    className="user-input"
                    type="password"
                    name="newPassword"
                    placeholder="New Password"
                  />
                  <ErrorMessage
                    style={{ color: "red", fontSize: "12px" }}
                    name="newPassword"
                    component="div"
                    className="error"
                  />
                </div>
                <div className="test-wrapp-forgot">
                  <Field
                    className="user-input"
                    type="password"
                    name="confirmPassword"
                    placeholder="Confirm Password"
                  />
                  <ErrorMessage
                    style={{ color: "red", fontSize: "12px" }}
                    name="confirmPassword"
                    component="div"
                    className="error"
                  />
                </div>
                <button className="login-btn" type="submit">
                  Confirm
                </button>
              </Form>
            </Formik>
          ) : (
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
              innerRef={emailFormRef}
            >
              <Form>
                <p className="headline-login">
                  You'll get an email with a reset link
                </p>
                <div className="test-wrapp-forgot">
                  <Field
                    className="user-input"
                    type="email"
                    name="email"
                    placeholder="Email ID"
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="error"
                  />
                </div>
                <button className="login-btn" type="submit">
                  Submit
                </button>
              </Form>
            </Formik>
          )}
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
