import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useUploadVillainDataMutation } from "../store/avatar/avatarApi";
import { toast, ToastContainer } from "react-toastify";
import Arrow from "../assets/images/next1.svg";
import ArrowP from "../assets/images/next3.svg";

function PromptsSelection({ character }) {
  const characterData = {
    "MAN OF STEAL": {
      copy: (
        <>
          Time to protect what's rightfully yours!
          <br />
          Create a hero who can guard the bucket against ‘MAN OF STEALl’!
        </>
      ),
      options: ["Bucket Guardian", "Crunch Protector", "Sharing Monitor"],
      special: [
        "Bucket Multiplication",
        "Bucket Shield Generator",
        "Anti-theft Aura",
      ],
    },
    "CRIMEMASTER CRUNCH": {
      copy: (
        <>
          The juiciest pieces need the mightiest hero! Get ready against <br />
          CRIMEMASTER CRUNCH !
        </>
      ),
      options: ["Piece Finder", "Chicken Maximizer", "Crunch Control"],
      special: ["Theft Radar", "Crispy Curator", " Perfect Piece Multiplier"],
    },
    DIPSTER: {
      copy: (
        <>Create a hero to protect the perfect crunch and save from DIPSTER!</>
      ),
      options: ["Crunch Guardian", " Dip Protector", " Crunch Control"],
      special: ["Stripes Explosion", "Popcorn Power", " Crunch Shield"],
    },
    BUNCOLLECTOR: {
      copy: (
        <>
          Time to defend the perfect burger! Create a hero who can fight
          BUNCOLLECTOR!
        </>
      ),
      options: ["Zinger Thunder", "Spice Master", " Bun Protection"],
      special: ["Spice Blast", "Crunch Shield", "Zing Blast"],
    },
  };

  const { copy, options, special } = characterData[character.name];
  const navigate = useNavigate();
  const [uploadVillainData] = useUploadVillainDataMutation();
  const [currentCard, setCurrentCard] = useState(1);
  const [selectedColor, setSelectedColor] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedSpecial, setSelectedSpecial] = useState("");

  useEffect(() => {
    setCurrentCard(1);
  }, [character]);

  const renderOptions = () => {
    switch (currentCard) {
      case 1:
        return (
          <div>
            <h3 className="option-title">POWER FOCUS</h3>
            <ul style={{ listStyleType: "none", padding: 0 }}>
              {options.map((option, index) => (
                <li
                  className="select-option"
                  key={option}
                  onClick={() => setSelectedOption(option)}
                  style={{
                    cursor: "pointer",
                    backgroundColor:
                      selectedOption === option ? "brown" : "white",
                    color: selectedOption === option ? "white" : "black",
                  }}
                >
                  <span className="abc">{String.fromCharCode(65 + index)}</span>{" "}
                  <span>{option}</span>
                </li>
              ))}
            </ul>
          </div>
        );
      case 2:
        return (
          <div>
            <h3 className="option-title">SPECIAL ABILITY</h3>
            <ul style={{ listStyleType: "none", padding: 0 }}>
              {special.map((ab, index) => (
                <li
                  className="select-option"
                  key={ab}
                  onClick={() => setSelectedSpecial(ab)}
                  style={{
                    cursor: "pointer",
                    backgroundColor: selectedSpecial === ab ? "brown" : "white",
                    color: selectedSpecial === ab ? "white" : "black",
                  }}
                >
                  <span className="abc">{String.fromCharCode(65 + index)}</span>{" "}
                  <span>{ab}</span>
                </li>
              ))}
            </ul>
          </div>
        );
      // case 3:
      //   return renderColors();
      default:
        return null;
    }
  };

  const renderColors = () => (
    <div>
      <h3 className="option-title">PICK YOUR SUIT COLOUR</h3>
      <div className="color-options">
        {["Classic Crunch", "Saucy and Spicy", "Hot and Crisp"].map((color) => (
          <div key={color} className="btn-color-wrapper">
            <button
              className="color-btn"
              onClick={() => setSelectedColor(color)}
              style={{
                background:
                  color === "Saucy and Spicy"
                    ? "linear-gradient(to bottom, orange 50%, black 50%)"
                    : color === "Classic Crunch"
                      ? "linear-gradient(to bottom, red 50%, white 50%)"
                      : "linear-gradient(to bottom, brown 50%, gold 50%)",
                border:
                  selectedColor === color
                    ? "2px solid white"
                    : "0.5px solid white",
              }}
            ></button>
            <span className="color-select-btn">{color}</span>
          </div>
        ))}
      </div>
    </div>
  );



  const handleNext = async () => {
    //console.log(currentCard);
    if (currentCard === 2) {
      // Check if all necessary data is selected
      if (selectedOption && selectedSpecial) {

        const payload = {
          villain: character?.name,
          power: selectedOption,
          ability: selectedSpecial,
          // suitColors: selectedColor,
        };

        //  console.log({ payload });
        try {
          const response = await uploadVillainData(payload).unwrap();
          const id = response?._id;
          navigate("/upload", { state: { character: character.name, id } });
        } catch (error) {
          console.error("Error uploading data:", error);
        }

      } else {
        toast.error("Please select all options before proceeding.", {
          position: "top-right",
        });
      }
    } else {
      setCurrentCard((prevCard) => {
        return prevCard + 1;
      });
    }
  };

  const handlePrevious = () => {
    setCurrentCard((prevCard) => {
      if (prevCard > 1) {
        return prevCard - 1;
      }
      return prevCard;
    });
  };

  // Check if Next button should be disabled
  const isNextDisabled = !(
    (currentCard === 1 && selectedOption) ||
    (currentCard === 2 && selectedSpecial)
  );

  // console.log(selectedColor);


  return (
    <div className="prompts-selection ">
      <ToastContainer />

      <div className="copy">{copy}</div>
      <h1 className="heading" style={{ textTransform: "uppercase" }}>
        ' {character?.name} '
      </h1>
      <div
        className="button-container"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "20px",
        }}
      >
        {currentCard > 1 && (
          <button
            className="arrow-btn arrow-p"
            onClick={handlePrevious}
            style={{ marginRight: "5px" }}
          >
            <img src={ArrowP} />
          </button>
        )}
        <div className="card">
          <div className="options">{renderOptions()}</div>
        </div>
        <button
          className="arrow-btn arrow-n"
          onClick={handleNext}
          disabled={isNextDisabled}
          style={{
            cursor: isNextDisabled ? "not-allowed" : "pointer",
          }}
        >
          <img src={Arrow} />
        </button>
      </div>
    </div>
  );
}

export default PromptsSelection;
