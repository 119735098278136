import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Link, useNavigate } from "react-router-dom";
import { object, string, ref } from "yup";
import { useSignUpUserMutation } from "../store/auth/authApi";
import { ToastContainer, toast } from "react-toastify";
import welcome from "../assets/images/welcome.png";
import Logo from "../assets/images/logounit.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";

function SignUp() {
  const navigate = useNavigate();
  const [signUpUser, { data: signUpResponse, error, isLoading }] =
    useSignUpUserMutation();

  const initialValues = {
    name: "",
    email: "",
    gender: "",
    instaId: "",
    password: "",
    confirmPassword: "",
    phone: "",
  };

  const validationSchema = object({
    name: string().required("Name is required"),
    email: string()
      .email("Invalid email address")
      .required("Email is required"),
    gender: string().required("Gender is required"),
    instaId: string().required("Instagram ID is required"),
    password: string()
      .min(6, "Password must be at least 6 characters")
      .required("Password is required"),
    confirmPassword: string()
      .oneOf([ref("password"), null], "Passwords must match")
      .required("Confirm password is required"),
    phone: string()
      .matches(/^[0-9]{10}$/, "Phone number must be 10 digits")
      .required("Phone number is required"),
  });

  return (
    <div className="sign-up">
      <div className="sign-up-wrapper container">
        <img className="welcome" src={welcome} />
        <Link to="/">
          <img className="logo-unit" src={Logo} alt="logo" />
        </Link>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={async (values, { resetForm }) => {
            if (values) {
              const { email, name, gender, password, instaId, phone } = values;

              const payload = {
                email,
                name,
                gender,
                password,
                instagram: instaId,
                phoneNumber: phone,
              };

              await signUpUser(payload)
                .unwrap()
                .then((response) => {
                  const { status, message } = response;
                  if (status === "success") {
                    toast.success(message, { position: "top-right" });
                    setTimeout(() => {
                      navigate("/sign-in");
                    }, 3000);
                    resetForm();
                  }
                })
                .catch((error) => {
                  toast.error(error?.message, { position: "top-right" });
                  resetForm();
                });
            }
          }}
        >
          {({ values, handleChange, handleBlur }) => (
            <Form className="sign-up-form">
              <div className="sign-up-field">
                <div className="input-wrap">
                  <Field
                    type="text"
                    id="name"
                    name="name"
                    className="form-control form-control-s"
                    placeholder="Name"
                  />
                  <ErrorMessage
                    name="name"
                    component="div"
                    className="error error-name"
                  // style={{ color: "red", fontSize: "12px", textAlign: "center" }}
                  />
                </div>
                <div className="input-wrap">
                  <Field
                    type="email"
                    id="email"
                    name="email"
                    className="form-control form-control-s"
                    placeholder="Email ID"
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="error error-email"
                  // style={{ color: "red", fontSize: "12px", textAlign: "center" }}
                  />
                </div>
              </div>

              <div className="sign-up-field">
                <div className="select-wr">
                  <div
                    as="select"
                    id="gender"
                    name="gender"
                    className="select-wrap"
                  >
                    <Field
                      as="select"
                      id="gender"
                      name="gender"
                      className="form-control custom-select-arrow"
                    >
                      <option value="">Select Gender</option>
                      <option value="MALE">Male</option>
                      <option value="FEMALE">Female</option>
                      <option value="OTHER">Other</option>
                    </Field>
                    <span>
                      <FontAwesomeIcon
                        icon={faAngleDown}
                        className="icon-down"
                      />
                    </span>
                  </div>
                  <ErrorMessage
                    name="gender"
                    component="div"
                    className="error error-gender"
                  />
                </div>

                <div className="input-wrap">
                  <Field
                    type="text"
                    id="instaId"
                    name="instaId"
                    className="form-control form-control-s"
                    placeholder="Insta ID"
                  />
                  <ErrorMessage
                    name="instaId"
                    component="div"
                    className="error error-email"
                  />
                </div>
              </div>

              <div className="sign-up-field">
                <div className="input-wrap">
                  <Field
                    type="password"
                    id="password"
                    name="password"
                    className="form-control form-control-s"
                    placeholder="Password"
                  />
                  <ErrorMessage
                    name="password"
                    component="div"
                    className="error error-email"
                  />
                </div>
                <div className="input-wrap">
                  <Field
                    type="password"
                    id="confirmPassword"
                    name="confirmPassword"
                    className="form-control form-control-s"
                    placeholder="Confirm Password"
                  />
                  <ErrorMessage
                    name="confirmPassword"
                    component="div"
                    className="error error-email"
                  />
                </div>
              </div>

              <div className="sign-up-field">
                <div className="input-wrap">
                  <Field
                    type="text"
                    id="phone"
                    name="phone"
                    className="form-control form-control-s"
                    placeholder="Contact"
                  />
                  <ErrorMessage
                    name="phone"
                    component="div"
                    className="error error-email"
                  />
                </div>
              </div>

              {isLoading && <div className="spinner"></div>}
              <div className="btn-al">
                <button type="submit" className="btn btn-primary login-btn">
                  Sign Up
                </button>
                {/* <a
                  className="forgot-password "
                  onClick={() => navigate("/sign-in")}
                  style={{ marginTop: "10px", textAlign: "center" }}
                >
                  <span>Already a user? </span> Log in
                </a> */}
                <span className="forgot-password">Already a user? &nbsp;
                  <a onClick={() => navigate("/sign-in")}>
                    Log in
                  </a>
                </span>
              </div>
            </Form>
          )}
        </Formik>
        <ToastContainer />
      </div>
    </div>
  );
}

export default SignUp;
