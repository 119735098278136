import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AppTable from "../components/AppTable";
import { ToastContainer } from "react-toastify";
import { logout } from "../store/auth/authSlice";
import { Link, useNavigate } from "react-router-dom";
import { useGetAvatarListQuery } from "../store/avatar/avatarApi";
import Logo from "../assets/images/logounit.png";
import moment from "moment/moment";

function DashBoardForAdmin() {
  const userInfo = useSelector((state) => state?.auth?.userInfo);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [currentPage, setCurrentPage] = useState(1);
  const { data: avatarsResponse, isLoading } = useGetAvatarListQuery({
    page: currentPage,
  });

  useEffect(() => {
    if (userInfo?.isAdmin !== true) {
      navigate("/");
    }
  }, [userInfo]);



  const columns = useMemo(
    () => [
      {
        Header: "#",
        id: "row",
        maxWidth: 50,
        Cell: ({ row }) => <div>{row.index + 1}</div>,
      },
      {
        Header: "User Name",
        accessor: "user",
        Cell: ({ value }) => value?.name || "N/A",
      },
      {
        Header: "Villain",
        accessor: "villain",
      },
      {
        Header: "Power",
        accessor: "power",
      },
      {
        Header: "Ability",
        accessor: "ability",
      },
      // {
      //   Header: "Stance",
      //   accessor: "stance",
      // },
      // {
      //   Header: "Suit Colors",
      //   accessor: "suitColors",
      // },
      {
        Header: "Address",
        accessor: "address",
      },
      {
        Header: "Avatar Image",
        accessor: "avtar",
        Cell: ({ value }) =>
          value ? (
            <img
              src={value}
              alt="Avatar"
              style={{ width: "60px", height: "100px", objectFit: "cover" }}
            />
          ) : (
            "No image available"
          ),
      },
      {
        Header: "Created At",
        accessor: "createdDate",
        Cell: ({ value }) => moment(value).format('DD-MM-YYYY hh:mm:ss a'),
      },
    ],
    []
  );

  return (
    <div className="admin-dash">
      <h2 className="heading-admin"> Admin Dashboard</h2>
      <Link className="logo-unit">
        <img src={Logo} alt="logo" />
      </Link>
      <button
        className="log-out"
        onClick={() => {
          dispatch(logout());
          navigate("/");
        }}
        style={{ position: "absolute", top: 10, right: 10 }}
      >
        Log out
      </button>
      <div className="admin-wrapper">
        <ToastContainer />

        {isLoading ? (
          <div className="spinner"></div>
        ) : (
          <>
            {avatarsResponse?.data.length > 0 ? (
              <div className="table-wrap">
                <AppTable
                  columns={columns}
                  data={avatarsResponse?.data || []}
                  meta={avatarsResponse?.meta}
                  onPageChange={setCurrentPage}
                />
              </div>
            ) : (
              <div>No avatars found.</div>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default DashBoardForAdmin;
