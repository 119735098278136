import React, { useState } from "react";
import { useSelector } from "react-redux";
// import '../styles/AddressModal.css';
import { useUpdateAddressMutation } from "../store/address/addressApi";
import { toast, ToastContainer } from "react-toastify";
function AddressModal({ isOpen, onClose, avatarData }) {
  const [newAddress, setNewAddress] = useState("");
  const userInfo = useSelector((state) => state.auth?.userInfo);
  const token = userInfo?.token;
  const mediaData = useSelector((state) => state.avatar.mediaData);

  const [updateAddress, { isLoading: isCreatingAddress }] =
    useUpdateAddressMutation();

  //console.log(mediaData);


  // Handle Add Address
  const handleAddAddress = async () => {
    if (newAddress.trim()) {
      const payload = {
        address: newAddress
      };
      const response = await updateAddress({ id: mediaData?._id, payload });
      // console.log(response);

      if (response?.data?.data?.address) {
        toast.success('Address saved', {
          position: "top-right",
        });
        onClose();
        setNewAddress("");
      }
      //console.log("Address added successfully", response);

    }
  };

  return (
    <>
      <ToastContainer />
      <div className={`modal ${isOpen ? "is-open" : ""}`}>
        <div className="modal-content">
          <div className="modal-header">
            <div className="avatar-address-container">
              {/* Avatar image on the left side */}
              <div className="avatar-container">
                {avatarData ? (
                  <img
                    src={avatarData}
                    alt="User Avatar"
                    className="avatar-image"
                  />
                ) : (
                  <div className="default-avatar">No Avatar</div>
                )}
              </div>

              {/* Address Form on the right side */}
              <div className="address-form">
                <h3>Add Your Address</h3>
                <div className="add-address">
                  <textarea
                    rows="4"
                    cols="50"
                    type="text"
                    value={newAddress}
                    onChange={(e) => setNewAddress(e.target.value)}
                    placeholder=""
                  />
                  <button
                    onClick={handleAddAddress}
                    disabled={isCreatingAddress || newAddress.trim() === ""}
                    className="add-address-btn"
                  >
                    Claim My Reward
                  </button>
                </div>
              </div>
            </div>

            {/* Close Modal Button */}
            <button className="close-modal" onClick={onClose}>
              &times;
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddressModal;
