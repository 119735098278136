import React, { useRef, useState } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import Webcam from "react-webcam";
import { useUploadImageMutation } from "../store/avatar/avatarApi";
import { setAvatarData } from "../store/avatar/avatarSlice";
import { toast, ToastContainer } from "react-toastify";
import { useDispatch } from "react-redux";
import Arrowp from "../assets/images/next3.svg";
import IMG from "../assets/images/img.png";
import Logo from "../assets/images/logounit.png";

const UploadAvatar = () => {
  const webcamRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [id, setId] = useState(location.state?.id || "");
  const [isUploading, setisUploading] = useState(false);

  const [showWebcam, setShowWebcam] = useState(false);
  const [imagePreview, setImagePreview] = useState(IMG);

  const [uploadImage] = useUploadImageMutation();

  const captureImage = async () => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImagePreview(imageSrc);
    const formData = new FormData();
    formData.append("image", dataURLtoBlob(imageSrc));

    try {
      setisUploading(true);
      const response = await uploadImage({ id, payload: formData }).unwrap();
      if (response?.avtar) {
        dispatch(setAvatarData(response));
        navigate(`/output?id=${response?._id}`);
        setisUploading(false)

      }
    } catch (error) {
      console.error("Error uploading image:", error);
    }
    finally {
      setisUploading(false)
    }
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const imagePreviewUrl = URL.createObjectURL(file);
      setImagePreview(imagePreviewUrl);
      const formData = new FormData();
      formData.append("image", file);

      try {
        setisUploading(true);
        const response = await uploadImage({ id, payload: formData }).unwrap();
        if (response?.avtar) {
          dispatch(setAvatarData(response));
          navigate(`/output?id=${response?._id}`);
          setisUploading(false)
        }
        // console.log({ response });

      } catch (error) {
        toast.error(error?.data?.message || "An error occurred", {
          position: "top-right",
        });
      }
      finally {
        setisUploading(false)
      }
    }
  };

  const handleUploadClick = (type) => {
    if (type === "webcam") {
      setShowWebcam(true);
    } else {
      document.getElementById("fileInput").click(); // Trigger file input
    }
  };

  // Helper function to convert data URL to Blob
  const dataURLtoBlob = (dataURL) => {
    const byteString = atob(dataURL.split(",")[1]);
    const mimeString = dataURL.split(",")[0].split(":")[1].split(";")[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  };

  return (
    <div className="img-uploader">
      <ToastContainer />
      <div className="upload-avatar-container container">
        <Link to="/">
          <img className="logo-unit" src={Logo} alt="logo" />
        </Link>
        <div className="header">
          <h2 className="heading-t">UPLOAD YOUR PHOTO</h2>
          <h1 className="heading">TO BECOME THE HERO KFC NEEDS</h1>
        </div>
        <div className="upload-avatar-wrapper">
          <button onClick={() => navigate("/select")} className="back-button">
            <img src={Arrowp} alt="arrow" />
          </button>
          <div className="upload-avatar-card">
            {/* Conditionally disable the buttons if webcam is active */}
            {!showWebcam && (
              <div className="card-item-gallary">
                <div className="copy-wr">
                  <ul className="ul-card">
                    <li className="li-card">
                      Guidelines for best results (well-lit, front-facing photo)
                    </li>
                    <li className="li-card">
                      Option to use device camera or upload from gallery{" "}
                    </li>
                  </ul>
                </div>
                <div className="img-wr">
                  {imagePreview && (
                    <>
                      <img src={imagePreview} alt="upload illustration" />
                    </>
                  )}

                  {isUploading ? (
                    <div className="spinner"></div>
                  ) : (
                    <div className="upload-btn-wrap">
                      <button
                        className={`btn-gallary`}
                        onClick={() => handleUploadClick("gallery")}
                      >
                        Upload from Gallery
                      </button>
                      <button
                        className={`btn-camera`}
                        onClick={() => handleUploadClick("webcam")}
                      >
                        Use Device Camera
                      </button>
                    </div>
                  )}

                </div>
              </div>
            )}

            {/* Show webcam when user chooses to take a pic */}
            {showWebcam && (
              <div className="webcam-wrapper">
                <Webcam
                  audio={false}
                  ref={webcamRef}
                  screenshotFormat="image/jpeg"
                  style={{
                    borderRadius: "10px",
                  }}
                />
                <button className="capture-btn" onClick={captureImage}>
                  Take a picture
                </button>
              </div>
            )}

            {/* Hidden file input for gallery upload */}
            <input
              type="file"
              accept="image/*"
              id="fileInput"
              style={{ display: "none" }}
              onChange={handleFileUpload}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadAvatar;
