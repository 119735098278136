import React from "react";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <div className="not-found-container">
      <div className="not-found-wrapper container">
        <h1 className="heading">Page Not Found</h1>
        <p className="copy">
          Sorry, the page you're looking for does not exist.
        </p>
        <Link to="/" className="cta-button">
          Go to Home
        </Link>
      </div>
    </div>
  );
};

export default NotFound;
