import {
    faArrowLeft,
    faArrowLeftLong,
    faArrowRight,
    faArrowRightLong,
    faClose,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useMemo, useState, useEffect } from 'react';
import { useTable, usePagination } from 'react-table';

const AppTable = ({ columns, data, meta, onPageChange, searchTerm, onSearchChanged }) => {

    const { pageSize: pageSize_, totalItems, totalPages, hasNextPage, hasPrevPage } = meta;

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page, // Instead of 'rows', we'll use 'page' for pagination
        nextPage,
        gotoPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        state: { pageIndex },
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0 }, // Initial page index
            pageCount: pageSize_,
        },
        usePagination
    );

    const memoizedColumns = useMemo(() => columns, [columns]);

    const [currentPage, setCurrentPage] = useState(0);
    const [debouncedSearch, setDebouncedSearch] = useState(searchTerm || ""); // Local state for debounced input


    useEffect(() => {
        onPageChange(currentPage + 1)
    }, [currentPage]);

    // Debounce logic for search
    useEffect(() => {
        const handler = setTimeout(() => {
            onSearchChanged(debouncedSearch); // Trigger search callback after debounce
        }, 300); // 300ms debounce delay

        return () => {
            clearTimeout(handler); // Cleanup timeout on component unmount or input change
        };
    }, [debouncedSearch]);

    return (
        <>
            {/* Search Input */}
            <div className="search-container">
                <input
                    type="text"
                    value={debouncedSearch}
                    onChange={(e) => setDebouncedSearch(e.target.value)}
                    placeholder="Search..."
                    className="search-input"
                />
                {
                    searchTerm ? (
                        <FontAwesomeIcon

                            onClick={() => setDebouncedSearch("")}
                            className="clear-search"
                            icon={faClose} />
                    ) : null
                }

            </div>

            <table {...getTableProps()} className="table">
                <thead>
                    {headerGroups.map((headerGroup) => (
                        <tr className="head-tr" {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <th className="head-table" {...column.getHeaderProps()}>{column.render('Header')}</th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map((row, i) => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map((cell) => {
                                    return <td className="head-data"{...cell.getCellProps()}>{cell.render('Cell')}</td>;
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>

            {totalPages > 1 ? (
                <div className="pagination-wrap">
                    <button className="btn-prev" onClick={() => {
                        setCurrentPage(0);
                    }} disabled={!hasPrevPage}>
                        <FontAwesomeIcon icon={faArrowLeftLong} />
                    </button>{' '}

                    <button className="btn-prev" onClick={() => {
                        if (currentPage + 1 > 1) {
                            setCurrentPage(currentPage - 1);
                        }
                    }} disabled={!hasPrevPage} >
                        <FontAwesomeIcon icon={faArrowLeft} />
                    </button>{' '}
                    <span>
                        Page  {currentPage + 1} of {totalPages}
                    </span>
                    <button className="btn-next" onClick={() => {
                        if (currentPage + 1 < totalPages) {
                            setCurrentPage(currentPage + 1);
                        }
                    }} disabled={!hasNextPage}>
                        <FontAwesomeIcon icon={faArrowRight} />
                    </button>{' '}

                    <button className="btn-next" onClick={() => {
                        setCurrentPage(totalPages - 1)
                    }} disabled={!hasNextPage}>
                        <FontAwesomeIcon icon={faArrowRightLong} />
                    </button>{' '}
                </div>
            ) : null}

        </>
    );
};

export default AppTable;
