import { api } from '../api';

export const addressApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getAddressList: builder.query({
            query: (token) => ({
                url: `/address/`,
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }),
            transformResponse: (response) => response.data,
        }),
        createAddress: builder.mutation({
            query: payload => ({
                url: '/address',
                method: 'POST',
                body: payload
            }),
            transformResponse: (response, meta, arg) => response.data,
        }),
        updateAddress: builder.mutation({
            query: ({ id, payload }) => ({
                url: `/avtars/${id}`,
                method: 'PUT',
                body: payload
            }),
        }),
        removeAddress: builder.mutation({
            query: (id) => ({
                url: `/address/${id}`,
                method: 'DELETE',
            }),
        }),
    })
})

export const { useGetAddressListQuery, useCreateAddressMutation, useUpdateAddressMutation, useRemoveAddressMutation } = addressApi;