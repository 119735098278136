import { api } from '../api';

export const avatarApi = api.injectEndpoints({
    endpoints: (builder) => ({
        uploadVillainData: builder.mutation({
            query: payload => ({
                url: '/avtars',
                method: 'POST',
                body: payload
            }),
            transformResponse: (response, meta, arg) => response.data,
        }),
        uploadImage: builder.mutation({
            query: ({ id, payload }) => ({
                url: `/avtars/upload/${id}`,
                method: 'PUT',
                body: payload
            }),
            transformResponse: (response, meta, arg) => response.data,
        }),
        getAvatarList: builder.query({
            query: ({ id, page, searchValue }) => ({
                url: `/avtars/?page=${page}&search=${searchValue}`,
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${id}`,
                },
            }),
            transformResponse: (response) => response.data,
        }),
        getUserAvatar: builder.query({
            query: (token) => {
                // console.log('Requesting avatar with token:', token); 
                return {
                    url: `/avtars/user`,
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                };
            },
            transformResponse: (response) => response.data,
        }),
    })
})

export const { useUploadVillainDataMutation, useUploadImageMutation, useGetAvatarListQuery, useGetUserAvatarQuery } = avatarApi;