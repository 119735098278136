import React from "react";
import PDFfile from "../assets/images/bgflame-min.pdf";

function Footer() {
  return (
    <div className="footer-container">
      <div className="footer-wrapper container">
        <a href={'https://drive.google.com/file/d/1EEpeP0im5v_HOxHwKPAgNUbzoJiSUrwW/view'} target="_blank" rel="noopener noreferrer">
          Terms & Conditions
        </a>
      </div>
    </div>
  );
}

export default Footer;
